import React, { useState, useEffect } from 'react';
import LocationLayout from '../../components/locationLayout';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Panier = () => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  const updateQuantity = (id, delta) => {
    const updatedCart = cart.map(item => 
      item.product.id === id 
        ? { ...item, quantity: Math.max(1, Math.min(item.product.data.stock, item.quantity + delta)) } 
        : item
    );
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const removeItem = (id) => {
    const updatedCart = cart.filter(item => item.product.id !== id);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.slice(0, wordLimit).join(" ") + (words.length > wordLimit ? " [...]" : "");
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart');
  };

  // To use to clear the memory in debugging
  // clearCart();

  return (
    <LocationLayout>
        <div className="location-home w-[1920px] max-w-[100%] mx-auto">
            <h1 className="text-3xl font-bold my-6">Votre Panier</h1>

            <div className="md:max-w-[50%] max-w-[100%] md:mx-auto mx-6 flex flex-col items-center mt-12 md:!ml-[20%]">  

        {cart.length === 0 ? (
          <p>Votre panier est vide.</p>
        ) : (
          <ul className="space-y-4 border-b pb-4">
            {cart.map((item, index) => {
                return (
              <li key={index} className="border-t pt-4 border-black-opacity60 flex items-center space-x-4">
                
                {/* IMAGE */}
                <div className="w-24 h-24 rounded">
                  <GatsbyImage image={getImage(item.product.data.image)} className="w-full h-full object-cover" />
                </div>

                {/* DETAILS */}
                <div className="flex-1">
                  <Link to={`/location/produit/${item.product.id}`} className="text-lg font-bold text-blue-500 hover:underline">
                    {item.product.data.name.text}
                  </Link>
                  <p className="text-sm !text-black">{truncateText(item.product.data.description.text, 25)}</p>

                  {/* QUANTITY CONTROL */}
                  <div className="flex items-center mt-0 space-x-4">
                    <div className="flex items-center border rounded-3xl py-1 px-1">
                      <button 
                        className="!px-1 !py-1 !bg-white !h-full !pl-4 !text-black !font-normal !border-0 !font-poppins !my-0 !mx-0"
                        onClick={() => updateQuantity(item.product.id, -1)}
                        disabled={item.product.data.quantity <= 1} >
                        -
                      </button>
                      <span className="px-2">{item.quantity}</span>
                      <button 
                        className="!px-1 !py-1 !bg-white !h-full !pr-4 !text-black !font-normal !border-0 !font-poppins !my-0 !mx-0"
                        onClick={() => updateQuantity(item.product.id, 1)}
                        disabled={item.product.data.quantity >= item.product.data.stock} >
                        +
                      </button>
                    </div>

                    {/* REMOVE BUTTON */}
                    <button 
                      className="px-4 py-1 !bg-white !text-black !font-openSans !font-thin !border-0 !text-xs !border-l-[1px] !rounded-none"
                      onClick={() => removeItem(item.product.id)} >
                      Supprimer
                    </button>
                  </div>
                </div>
              </li>
            )})}
          </ul>
        )}
        <div className='!flex my-12 h-12 justify-start w-full'>
          <form action={"/location/contact"}>
            <button className="w-36 mr-3 !h-full">
              Soumission
            </button>
          </form>

          {process.env.NODE_ENV === "development" && (
            <button 
              className="!h-full ml-3"
              onClick={() => clearCart()} >
              Vider le panier
            </button>
          )}

          </div>
        </div>
      </div>
    </LocationLayout>
  );
};

export default Panier;